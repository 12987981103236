// 存放登录后才有的数据Store
import { defineStore, acceptHMRUpdate } from 'pinia'
import { userInfo, getUserBalance, messageCount } from '@/api/user'
import { updateAutoTransfer } from '@/api/usercenter.js'
import { todayEarnings } from '@/api/proxy.js'
import { ElMessage } from 'element-plus'
import dayjs from 'dayjs'
export const useUserStore = defineStore('userStore', {
  state: () => {
    return {
      hasLogin: false, // 是否登录
      userInfo: {}, // 用户info
      balance: 0, // 余额
      earnings: 0, // 今日收益
      balanceLoading: false, // 余额接口loading状态
      msgCount: 0 // 站内信数量
    }
  },
  persist: {
    paths: ['hasLogin', 'userInfo']
  },

  getters: {
    // 是否是代理
    isProxy(state) {
      if (state.userInfo.userName) {
        return state.hasLogin && state.userInfo.userType == 1
      }
      return false
    }
  },

  actions: {
    setAttribute(key, val) {
      this[key] = val
    },

    // 今日收益
    async todayEarningsFn() {
      let { result: res } = await todayEarnings()
      if (res && res.code == 0) {
        this.earnings = res.commission
      }
    },

    async messageCountFn() {
      const { result } = await messageCount()
      if (result) {
        this.msgCount = result.count
      }
    },

    async userInfoFn() {
      const { result } = await userInfo()
      if (result) {
        this.userInfo = result.user
        this.balance = result.user.balance
      }
    },
    async getUserBalanceFn(isMessage = true, callback) {
      if (this.balanceLoading) {
        return
      }
      this.setAttribute('balanceLoading', true)
      const startTime = dayjs()
      const { result } = await getUserBalance()
      const endTime = dayjs()
      const duration = endTime.diff(startTime)
      if (duration < 1000) {
        setTimeout(() => {
          this.setAttribute('balanceLoading', false)
        }, 1000 - duration)
      } else {
        this.setAttribute('balanceLoading', false)
      }
      if (result) {
        const { balance } = result
        if (isMessage) {
          ElMessage.success('已刷新')
        }
        this.setAttribute('balance', balance)
        callback && callback()
      }
    },

    async updateAutoTransferFn() {
      const data = {
        autoTransfer: Number(!this.userInfo.autoTransfer)
      }
      const { result } = await updateAutoTransfer(data)
      if (result) {
        const msg = this.userInfo.autoTransfer ? '关闭' : '开启'
        ElMessage.success(`免额转换已${msg}`)
        this.userInfo.autoTransfer = Number(!this.userInfo.autoTransfer)
        return true
      }
      return false
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
