import { HRouteView } from '@/components'
import img1 from '@/assets/usercenter/icon1.svg'
import img2 from '@/assets/usercenter/icon2.svg'
import img3 from '@/assets/usercenter/icon3.svg'
import img4 from '@/assets/usercenter/icon4.svg'
import img5 from '@/assets/usercenter/icon5.svg'
import img6 from '@/assets/usercenter/icon6.svg'
import img7 from '@/assets/usercenter/icon7.svg'
import img8 from '@/assets/usercenter/icon8.svg'
import img9 from '@/assets/usercenter/icon9.svg'
import img10 from '@/assets/usercenter/icon10.svg'
import img11 from '@/assets/usercenter/icon11.svg'
const userRoutes = [
  {
    path: '/memberCentre/userInfo',
    name: 'memberCentre/userInfo',
    component: () => import('@/views/memberCentre/userInfo.vue'),
    meta: {
      title: '账号总览',
      icon: img1,
      permission: 'userInfo',
      index: '1'
    }
  },
  {
    path: '/memberCentre/userSetting',
    name: 'userSetting',
    redirect: '/memberCentre/userSetting/memberInfo',
    meta: {
      title: '账号设置',
      isThreeLevelMenu: true,
      icon: img2,
      permission: 'memberInfo',
      index: '2'
    },
    component: HRouteView,
    children: [
      {
        path: '/memberCentre/userSetting/memberInfo',
        name: 'userSetting/MemberInfo',
        component: () => import('@/views/memberCentre/memberInfo.vue'),
        meta: {
          title: '会员信息',
          permission: 'memberInfo',
          index: '2-1'
        }
      },
      {
        path: '/memberCentre/userSetting/wallet',
        name: 'userSetting/wallet',
        component: () => import('@/views/memberCentre/wallet.vue'),
        meta: {
          title: '钱包管理',
          permission: 'wallet',
          index: '2-2'
          // hasPayPs: true // 需要支付密码才能进入
        }
      },
      {
        path: '/memberCentre/userSetting/safety',
        name: 'userSetting/safety',
        component: () => import('@/views/memberCentre/safety.vue'),
        meta: {
          title: '安全管理',
          permission: 'safety',
          index: '2-3'
        }
      }
    ]
  },
  {
    path: '/memberCentre/deposit',
    name: 'memberCentre/deposit',
    component: () => import('@/views/memberCentre/deposit.vue'),
    meta: {
      title: '充值',
      icon: img3,
      permission: 'deposit',
      index: '3'
    }
  },
  {
    path: '/memberCentre/withdraw',
    name: 'memberCentre/withdraw',
    component: () => import('@/views/memberCentre/withdraw.vue'),
    meta: {
      title: '提现',
      icon: img4,
      permission: 'withdraw',
      index: '4',
      hasPayPs: true // 需要支付密码才能进入
    }
  },
  {
    path: '/memberCentre/freeWithdraw',
    name: 'memberCentre/freeWithdraw',
    component: () => import('@/views/memberCentre/freeWithdraw.vue'),
    hidden: false,
    meta: {
      title: '免提直充',
      icon: img5,
      permission: 'freeWithdraw',
      index: '5',
      subTxt: '领优惠' // 右侧是否有内容
    }
  },
  {
    path: '/memberCentre/balanceTransfer',
    name: 'memberCentre/balanceTransfer',
    component: () => import('@/views/memberCentre/balanceTransfer.vue'),
    meta: {
      title: '额度转换',
      icon: img6,
      permission: 'balanceTransfer',
      index: '6'
    }
  },
  {
    path: '/memberCentre/finance',
    name: 'finance',
    redirect: '/memberCentre/finance/depositRecord',
    meta: {
      title: '财务记录',
      isThreeLevelMenu: true,
      icon: img7,
      permission: 'finance',
      index: '7'
    },
    component: HRouteView,
    children: [
      {
        path: '/memberCentre/finance/depositRecord',
        name: 'finance/depositRecord',
        component: () => import('@/views/memberCentre/depositRecord.vue'),
        meta: {
          title: '充值记录',
          permission: 'depositRecord',
          index: '7-1'
        }
      },
      {
        path: '/memberCentre/finance/withdrawRecord',
        name: 'finance/withdrawRecord',
        component: () => import('@/views/memberCentre/withdrawRecord.vue'),
        meta: {
          title: '提现记录',
          permission: 'withdrawRecord',
          index: '7-2'
        }
      },
      {
        path: '/memberCentre/finance/transferRecord',
        name: 'finance/transferRecord',
        component: () => import('@/views/memberCentre/transferRecord.vue'),
        meta: {
          title: '额度转换记录',
          permission: 'transferRecord',
          index: '7-3'
        }
      },
      {
        path: '/memberCentre/finance/accountChange',
        name: 'finance/accountChange',
        component: () => import('@/views/memberCentre/accountChange.vue'),
        meta: {
          title: '账变记录',
          permission: 'accountChange',
          index: '7-4'
        }
      }
    ]
  },
  {
    path: '/memberCentre/betting',
    name: 'betting',
    redirect: '/memberCentre/betting/lotteryBet',
    meta: {
      title: '投注记录',
      isThreeLevelMenu: true,
      icon: img8,
      permission: 'betting',
      index: '8'
    },
    component: HRouteView,
    children: [
      {
        path: '/memberCentre/betting/lotteryBet',
        name: 'betting/lotteryBet',
        component: () => import('@/views/memberCentre/lotteryBet.vue'),
        meta: {
          title: '彩票投注记录',
          permission: 'lotteryBet',
          index: '8-1'
        }
      },
      {
        path: '/memberCentre/betting/thirdBetRecord',
        name: 'betting/thirdBetRecord',
        component: () => import('@/views/memberCentre/thirdBetRecord.vue'),
        meta: {
          title: '第三方投注记录',
          permission: 'thirdBetRecord',
          index: '8-2'
        }
      }
    ]
  },
  {
    path: '/memberCentre/returnSelf',
    name: 'memberCentre/returnSelf',
    component: () => import('@/views/memberCentre/returnSelf.vue'),
    meta: {
      title: '自助返水',
      icon: img9,
      permission: 'returnSelf',
      index: '9'
    }
  },
  {
    path: '/memberCentre/vip',
    name: 'memberCentre/vip',
    component: () => import('@/views/memberCentre/vip.vue'),
    meta: {
      title: 'VIP权益',
      icon: img10,
      permission: 'vip',
      index: '10'
    }
  },
  {
    path: '/memberCentre/notify',
    name: 'memberCentre/notify',
    component: () => import('@/views/memberCentre/notify.vue'),
    meta: {
      title: '站内消息',
      icon: img11,
      permission: 'notify',
      index: '11'
    }
  }
]

export default userRoutes
