<template>
  <div>
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
    <LoginDialog ref="loginRef" />
    <template
      v-if="userStore.hasLogin && userStore.userInfo && !userStore.userInfo.setPayPwdStatus"
    >
      <NeedSetPayPasswordDialog ref="needSetPayPasswordDialogRef" />
      <SetPayPasswordDialog ref="setPayPasswordDialogRef" @success="setPW" />
    </template>
  </div>
</template>

<script setup>
import { onBeforeMount, onMounted, ref, unref, watch, onBeforeUpdate, provide } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import LoginDialog from '@/components/LoginDialog.vue'
import NeedSetPayPasswordDialog from '@/components/needSetPayPasswordDialog.vue'
import SetPayPasswordDialog from '@/components/setPayPasswordDialog.vue'
import { useHomeStore } from '@/stores/home.js'
import { useUserStore } from '@/stores/user.js'

provide('toLogin', openLoginDialog)
provide('openNeedSetPayPasswordDialog', openNeedSetPayPasswordDialog)
provide('openSetPayPasswordDialog', openSetPayPasswordDialog)

const homeStore = useHomeStore()
const userStore = useUserStore()

const router = useRouter()
const loginRef = ref()
const needSetPayPasswordDialogRef = ref()
const setPayPasswordDialogRef = ref()
onBeforeMount(async () => {
  await homeStore.getAllGameFn()
})

onMounted(() => {
  if (userStore.hasLogin) {
    userStore.userInfoFn()
  }
})

router.beforeEach((to, from, next) => {
  if (to.meta.login) {
    if (!userStore.hasLogin) {
      router.push('/')
      openLoginDialog()
    } else {
      if (to.meta.hasPayPs && !userStore.userInfo.setPayPwdStatus) {
        openNeedSetPayPasswordDialog()
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

watch(
  () => userStore.hasLogin,
  (val) => {
    if (val) {
      userStore.userInfoFn()
    }
  }
)

watch(
  () => router.currentRoute.value,
  (newValue) => {
    if (newValue.meta.navId) {
      homeStore.setAttribute('navStatus', newValue.meta.navId)
    } else {
      homeStore.setAttribute('navStatus', '')
    }
  },
  { immediate: true }
)

function openLoginDialog() {
  unref(loginRef).open()
}

function openNeedSetPayPasswordDialog() {
  unref(needSetPayPasswordDialogRef).open()
}

function openSetPayPasswordDialog() {
  unref(setPayPasswordDialogRef).open()
}

function setPW() {
  userStore.userInfoFn()
  unref(needSetPayPasswordDialogRef).close()
}
</script>

<style scoped lang="scss"></style>
