<template>
  <div class="sign_box" v-loading="loading">
    <el-dialog
      v-model="visibleSign"
      class="sign_dialog"
      title=""
      :show-close="false"
      width="450"
      center
    >
      <div class="top_info">
        <div class="top_box">
          已签
          <p>{{ signedDays }}</p>
          天
        </div>
      </div>
      <div class="info_list">
        <div
          class="info_item"
          v-for="(item, index) in configList"
          :key="index"
          :class="index == 6 ? 'more_width' : ''"
        >
          <div
            class="common_item"
            v-if="index != 6"
            :class="{
              signed_days: item.signDay <= signedDays,
              will_signed: item.signDay == signedDays + 1 && !signedToday
            }"
          >
            <div class="top_img">
              <img
                src="@/assets/sign/signed.png"
                alt=""
                class="coin"
                v-if="item.signDay <= signedDays"
              />
              <img src="@/assets/sign/coin.png" alt="" class="coin" v-else />
            </div>
            <div class="bottom_days">第{{ item.signDay }}天</div>
          </div>
          <div
            v-else
            class="common_item last_item"
            :class="{
              signed_days: signedDays == 7,
              will_signed: signedDays == 6 && !signedToday
            }"
          >
            <div class="left_item">
              <img src="@/assets/sign/signed.png" class="coin" alt="" v-if="signedDays == 7" />
              <img src="@/assets/sign/red_bg.png" alt="" class="red_beg" v-else />
            </div>
            <div class="right_item">
              <div class="top_con" v-if="signedDays != 7">神秘彩金</div>
              <div class="days_right">第{{ item.signDay }}天</div>
            </div>
          </div>
        </div>
      </div>
      <div class="sing_btn" @click="toSign" v-if="!signedToday">签到</div>
      <div class="sing_btn has_signed" @click="hasSignedAct" v-else>今日已签到</div>
      <div class="bottom_btn">
        <div class="act_btn" @click="showRules">活动规则</div>
        <div class="act_btn" @click="showRecords">签到记录</div>
      </div>
      <div class="close_con" @click="visibleSign = false">
        <el-icon><CircleClose color="#fff" /></el-icon>
      </div>
    </el-dialog>
    <el-dialog
      v-model="rulesVisiable"
      class="rules_dialog"
      title="活动规则"
      :show-close="true"
      width="450"
      center
    >
      <div class="content" v-html="signDetails.detail"></div>
    </el-dialog>
    <el-dialog
      v-model="recordsVisiable"
      class="records_box"
      title="签到记录"
      :show-close="true"
      width="620"
      center
    >
      <div class="form_item">
        <div class="label">选择日期</div>
        <el-date-picker
          style="width: 220px; flex-grow: inherit"
          v-model="time"
          :clearable="false"
          type="daterange"
          unlink-panels
          range-separator="~"
          start-placeholder="起始时间"
          end-placeholder="截止时间"
          :shortcuts="shortcuts"
          value-format="YYYY-MM-DD"
        />
        <el-button type="primary" @click="getRecords">查询</el-button>
      </div>
      <div class="table_cont">
        <el-table :data="lists" style="width: 100%">
          <el-table-column prop="createTime" label="签到时间" min-width="120px" align="center" />
          <el-table-column prop="award" label="彩金" min-width="120px" align="center">
            <template #default="scope">
              <el-text type="primary">￥{{ scope.row.award }}</el-text>
            </template>
          </el-table-column>
          <el-table-column prop="signDetail" label="详情" min-width="120px" align="center" />
        </el-table>
        <div class="page_box">
          <el-row justify="center" align="middle" style="padding: 10px" class="page_cont">
            <el-pagination
              :total="totalCount"
              v-model:page-size="queryParams.limit"
              :page-sizes="[10, 20, 50, 100, 200]"
              @size-change="handleSizeChange"
              @change="pageChange"
              layout=" total,sizes,prev, pager, next"
              ref="pageRef"
              prev-text="上一页"
              next-text="下一页"
            >
            </el-pagination>
          </el-row>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { signDetail, signStatus, signPost, signRecord } from '@/api/index.js'
import { ElMessage } from 'element-plus'
import { shortcuts } from '@/utils/dateUtils'
import dayjs from 'dayjs'
const signedDays = ref(0)
const configList = ref([
  { signDay: 1, id: 0 },
  { signDay: 2, id: 1 },
  { signDay: 3, id: 2 },
  { signDay: 4, id: 3 },
  { signDay: 5, id: 4 },
  { signDay: 6, id: 5 },
  { signDay: 7, id: 6 }
])
const signedToday = ref(false)
const visibleSign = ref(false)
const loading = ref(false)
const signDetails = ref({})
const showDialog = () => {
  getSignInfo()
}
const getSignInfo = async () => {
  loading.value = true
  let { result: res } = await signDetail()
  let { result: resStatus } = await signStatus()
  signedDays.value = resStatus.data.signCount
  signedToday.value = resStatus.data.sign
  signDetails.value = res.data
  visibleSign.value = true
  loading.value = false
}
const toSign = async () => {
  loading.value = true
  let { result: res } = await signPost()
  if (res && res.code == 0) {
    ElMessage.success(res.msg)
    getSignInfo()
  }
}
const hasSignedAct = () => {
  ElMessage.info('今日您已签到')
}
const rulesVisiable = ref(false)
const showRules = () => {
  if (signDetails.value.status == 1) {
    ElMessage.info('活动暂未开始')
  } else {
    rulesVisiable.value = true
  }
}
const recordsVisiable = ref(false)
const showRecords = async () => {
  visibleSign.value = false
  recordsVisiable.value = true
  getRecords()
}
const lists = ref([])
const queryParams = ref({
  beginTime: dayjs().format('YYYY-MM-DD'),
  endTime: dayjs().format('YYYY-MM-DD'),
  limit: 10,
  page: 1
})
const time = ref([dayjs().subtract(1, 'months').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')])
const getQuickSearch = (item) => {
  time.value = [item.beginTime, item.endTime]
  queryParams.value.beginTime = item.beginTime
  queryParams.value.endTime = item.endTime
  getRecords()
}

const totalPages = ref(0)
const totalCount = ref(0)
const handleSizeChange = (num) => {
  queryParams.value.limit = num
  getRecords()
}
const pageChange = (current) => {
  queryParams.value.page = current
  getRecords()
}
const getRecords = async () => {
  loading.value = true
  let params = {
    ...queryParams.value,
    beginTime: time.value[0],
    endTime: time.value[1]
  }
  let { result: res } = await signRecord(params)
  lists.value = res.data.list
  totalPages.value = res.data.totalPage
  totalCount.value = res.data.totalCount
}
defineExpose({ showDialog })
</script>
<style lang="scss" scoped>
.sign_box {
  :deep(.sign_dialog) {
    background: none;
    box-shadow: none;
    background: url('@/assets/sign/big_bg.png') no-repeat center;
    background-size: 100% 100%;
    position: relative;
    padding: 0 70px;
    box-sizing: border-box;
    height: 510px;
    .top_info {
      margin-top: 120px;
      display: flex;
      justify-content: flex-end;
      color: #767d84;
      font-size: 16px;
      align-items: center;
      padding-right: 10px;
      box-sizing: border-box;
      .top_box {
        display: flex;
        align-items: center;
      }
      p {
        margin: 0 4px;
        color: #ff7422;
      }
    }
    .info_list {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 10px;
      .info_item {
        width: 23%;
        height: 88px;
        margin-bottom: 15px;
        .common_item {
          width: 100%;
          height: 100%;
          background: #fff;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          color: #767d84;
          .coin {
            width: 34px;
            height: auto;
          }
          .red_beg {
            width: 45px;
            height: auto;
          }
          .bottom_days {
            margin-top: 5px;
          }
        }
        .last_item {
          flex-direction: row;
          .right_item {
            margin-left: 10px;
            .top_con {
              color: #ff7422;
            }
            .days_right {
              margin-top: 5px;
            }
          }
        }
        .signed_days {
          color: #10c623;
        }
        .will_signed {
          background-image: linear-gradient(to right, #f5c75e 0%, #ff8549 100%);
          color: #fff;
          .right_item {
            .top_con {
              color: #fff;
            }
          }
        }
      }
      .more_width {
        width: 49%;
      }
    }
    .sing_btn {
      cursor: pointer;
      margin-top: 10px;
      width: 100%;
      height: 42px;
      text-align: center;
      line-height: 42px;
      font-size: 15px;
      color: #fff;
      font-weight: 500;
      border-radius: 22px;
      border: solid 2px #ffce66;
      background-image: linear-gradient(to right, #f5c75e 0%, #ff8549 100%);
    }
    .has_signed {
      border: solid 2px #ffce66;
      background-image: linear-gradient(to right, #f5c75e 0%, #ff8549 100%);
      color: rgba($color: #fff, $alpha: 0.5);
    }
    .bottom_btn {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      .act_btn {
        width: 75px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 20px;
        border: solid 1px #ff7422;
        font-size: 12px;
        color: #ff7422;
        cursor: pointer;
      }
    }
    .close_con {
      cursor: pointer;
      position: absolute;
      top: 5px;
      right: 20px;
      font-size: 32px;
    }
  }
  :deep(.rules_dialog) {
    padding: 0;
    padding-bottom: 30px;
    .el-dialog__header {
      height: 52px;
      color: #fff;
      background: $primaryColor;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 0;
      .el-dialog__title {
        color: #fff;
      }
      .el-dialog__close {
        color: #fff;
        font-size: 18px;
      }
    }
    .el-dialog__body {
      padding: 16px 20px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      .content {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
  :deep(.records_box) {
    .el-dialog__header {
      text-align: left;
    }
    .el-dialog__body {
      .form_item {
        display: flex;
        align-items: center;
        gap: 10px;
        .label {
          font-size: 14px;
          color: #5f5f5f;
        }
        :deep(.el-input__wrapper) {
          flex-grow: inherit;
        }
      }
      .table_cont {
        margin-top: 20px;
        .el-table {
          th.el-table__cell {
            background-color: #f5f5f5;
            color: #999999;
          }

          .el-table__body-wrapper {
            border-radius: 4px;
          }
        }
        .btn-prev,
        .btn-next {
          width: 60px;
          border-radius: 5px;
          height: 30px;
          display: flex;
          margin-left: 10px;
          align-items: center;
          border: solid 1px #d9dadc;
          background-image: linear-gradient(to bottom, #fff, #eeeeef);
        }
      }
    }
  }
}
</style>
