<template>
  <div class="fixed_bar_box" :style="{ height: rightDomHeight + 'px' }">
    <Transition
      enter-active-class="animate_slideInRight"
      leave-active-class="animate_slideOutRight"
      mode="out-in"
    >
      <div class="fixed_bar" v-if="showFixedBar">
        <div v-for="(item, index) in barList" :key="index" @click="chooseBar(item)">
          <div class="bar_item" v-if="item.show && item.type != 'service'">
            <el-popover placement="left" trigger="hover" :content="item.content">
              <template #reference>
                <div class="item">
                  <img :src="item.icon" alt="" />
                  <p class="name" v-if="item.name">{{ item.name }}</p>
                </div>
              </template>

              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                "
                v-if="item.type == 'qr'"
              >
                <p class="name">{{ item.content }}</p>
                <img :src="qrVal" alt="" />
              </div>
            </el-popover>
          </div>

          <div class="bar_item" v-if="item.show && item.type == 'service'">
            <h-customer-service>
              <el-popover placement="left" trigger="hover" :content="item.content">
                <template #reference>
                  <div class="item">
                    <img :src="item.icon" alt="" />
                    <p class="name" v-if="item.name">{{ item.name }}</p>
                  </div>
                </template>
              </el-popover>
            </h-customer-service>
          </div>
        </div>
        <div class="bottom_hide" @click="showFixedBar = false">
          <p class="title">隐藏</p>
          <i class="gs-arrowb" style="transform: rotateZ(180deg)"></i>
        </div>
      </div>
      <div class="bottom_show" v-else-if="!showFixedBar" @click="showFixedBar = true">
        <img :src="OpenFixedBar" alt="显示" />
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import QRCode from 'qrcode'

import { useHomeStore } from '@/stores/home.js'
import Sign from '@/assets/home/sign.png'
import Turnable from '@/assets/home/turnable.png'
import Redbag from '@/assets/home/redbag.png'
import Service from '@/assets/home/service.svg'
import img5 from '@/assets/home/icon_mtzc.png'
import QQ from '@/assets/home/qq.svg'
import Phone from '@/assets/home/phone.svg'
import Qr from '@/assets/home/qr.svg'
import OpenFixedBar from '@/assets/home/open_fixed_bar.png'
import { useUserStore } from '@/stores/user.js'
const userStore = useUserStore()
const router = useRouter()
const homeStore = useHomeStore()
const emits = defineEmits(['chooseBarItemFn'])
const props = defineProps({
  rightDomHeight: {
    type: [Number, String],
    default: 352
  }
})
const showFixedBar = ref(true)
const barList = ref([
  {
    name: '签到',
    icon: Sign,
    content: '每日签到，彩金领不停',
    type: 'sign',
    needLogin: true,
    show: homeStore.activitySignStatus
  },
  {
    name: '转盘',
    icon: Turnable,
    content: '参与转盘活动',
    type: 'turnable',
    needLogin: true,
    show: homeStore.activityRouletteStatus
  },
  {
    name: '红包雨',
    icon: Redbag,
    content: '红包天天有',
    type: 'redrain',
    needLogin: true,
    show: homeStore.activityHongbaoyuStatus
  },
  {
    name: '免提直充',
    icon: img5,
    content: '免提直充，享充值优惠',
    type: 'mtzc',
    needLogin: true,
    show: homeStore.activityNotWithdrawStatus
  },
  {
    name: '在线客服',
    icon: Service,
    content: '联系在线客服',
    type: 'service',
    needLogin: false,
    show: true
  },
  // { name: '', icon: QQ, content: 'QQ', type: 'qq', needLogin: false, show: true },
  {
    name: '电话客服',
    icon: Phone,
    content: '联系电话： Null',
    type: 'phone',
    needLogin: false,
    show: true
  },
  { name: '下载APP', icon: Qr, content: '扫码下载APP', type: 'qr', needLogin: false, show: true }
])
const qrVal = ref('')
onMounted(async () => {
  qrVal.value = await new QRCode.toDataURL(homeStore.appDownloadLink)
})
const chooseBar = (item) => {
  emits('chooseBarItemFn', item)
}
</script>

<style scoped lang="scss">
.fixed_bar_box {
  display: flex;
  align-items: center;
  .fixed_bar {
    background: #fff;
    border-radius: 6px 0 0 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    color: #999;
    padding: 10px 0;
    box-sizing: border-box;
    .bar_item {
      padding: 0 9px;
      margin-bottom: 10px;
      .item {
        width: 48px;
        cursor: pointer;
        padding: 3px 0;
        min-height: 48px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          height: 30px;
          width: auto;
        }

        .name {
          margin-top: 6px;
          text-align: center;
          color: #999;
        }

        &:hover {
          background: #eeeeee;
          border-radius: 4px;
          color: #5f5f5f;
        }
      }
    }

    .bottom_hide {
      width: 66px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #999999;
      cursor: pointer;
      height: 30px;

      i {
        font-size: 12px;
      }

      &:hover {
        // background: #eeeeee;
        // border-radius: 4px;
        // color: #5f5f5f;
        color: $primaryColor;
      }
    }
  }
  .bottom_show {
    width: 36px;
    height: 86px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

@keyframes slideInRight {
  0% {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translateZ(0);
  }
}

.animate_slideInRight {
  animation-name: slideInRight;
  animation-duration: 0.2s;
  animation-fill-mode: both;
}
@keyframes slideOutRight {
  0% {
    transform: translateZ(0);
  }

  to {
    transform: translate3d(100%, 0, 0);
    visibility: hidden;
  }
}

.animate_slideOutRight {
  animation-name: slideOutRight;
  animation-duration: 0.3s;
  animation-fill-mode: both;
}
</style>
