import { defineStore, acceptHMRUpdate } from 'pinia'
import {
  // homeDomain,
  // getAllLottery,
  // getAllPlat ,
  getAllGame
} from '@/api/index'
export const useHomeStore = defineStore('homeStore', {
  persist: true,
  state: () => {
    return {
      pageHint: 'home',
      needRefCode: null,
      cancelOrderEnabled: '0', // 是否可以撤单
      domainName: '',
      logo: '',
      logoImg: '',
      loginImg: '',
      domainInform: '',
      viewPath: '',
      domain: '',
      activitySignStatus: false, // 签到
      activityRouletteStatus: false, // 轮盘
      activityHongbaoyuStatus: false, // 红包雨
      activityNotWithdrawStatus: false, // 是否开启优惠活动
      appDownloadLink: '',
      lineCheckLink: '',

      topNav: [], // 头部导航
      navStatus: '0', // 头部导航ID
      allGameList: [], // 全部game
      allLotteryList: [], // 全部彩种
      allLotteryObj: {}, // 全部彩种  (已分组)
      // allPlatList: [], // 第三方游戏
      allPlatListObj: null // 第三方游戏 (已分组)
    }
  },

  getters: {
    hotList(state) {
      if (state.allLotteryList.length) {
        return state.allLotteryList.filter((item) => item.hot === 1).splice(0, 16)
      }
      return []
    }
  },

  actions: {
    setAttribute(key, val) {
      this[key] = val
    },

    // async getAllPlatFn() {
    //   const { result } = await getAllPlat()
    //   if (result) {
    //     this.allPlatList = result.list
    //   }
    // },

    // 获取网站配置
    setHomeDomainData(result) {
      // const { result } = await homeDomain()
      if (result) {
        this.needRefCode = result.param.needRefCode
        this.cancelOrderEnabled = result.param.cancelOrderEnabled
        this.domainName = result.domain.domainName
        this.logoImg = result.domain.logo
        this.loginImg = result.domain.logo1
        this.logo = result.domain.logo2
        this.domainInform = result.domain.domainInform
        this.viewPath = result.domain.kefuLink
        this.domain = result.domain.domain
        this.activitySignStatus = result.activityStatus.activitySignStatus
        this.activityRouletteStatus = result.activityStatus.activityRouletteStatus
        this.activityHongbaoyuStatus = result.activityStatus.activityHongbaoyuStatus
        this.activityNotWithdrawStatus = result.activityStatus.activityNotWithdrawStatus
        this.appDownloadLink = result.domain.appDownloadLink
        this.lineCheckLink = result.domain.lineCheckLink
      }
    },

    async getAllGameFn() {
      const { result } = await getAllGame()
      if (result) {
        this.allGameList = result.data
        this.setTopNav(result.data)
        this.setGameData(result.data)
      }
    },

    setGameData(list) {
      const lotteryItem = list.find((item) => item.catCode === 'lottery')
      if (lotteryItem) {
        this.allLotteryList = lotteryItem?.games ?? []
        this.groupAllLottery(lotteryItem.games)
      }
      this.allPlatListObj = this.groupAllPlatListObj(list)
    },
    setTopNav(list) {
      const navCodes = list.map((item) => {
        return { catCode: item.catCode, catNme: item.catNme }
      })
      const defNavMap = [
        {
          navId: '0',
          catNme: '首页',
          url: '/home'
        }
      ]
      const configNavMap = [
        {
          navId: '1',
          catNme: '彩票游戏',
          type: 0,
          catCode: 'lottery',
          url: '/lottery'
        },
        {
          navId: '4',
          catNme: '真人视讯',
          type: 1,
          catCode: 'live',
          url: '/live'
        },
        {
          navId: '5',
          catNme: '电子游戏',
          type: 2,
          catCode: 'ele',
          url: '/electronic'
        },
        {
          navId: '3',
          catNme: '体育赛事',
          type: 3,
          catCode: 'sport',
          url: '/sports'
        },
        {
          navId: '2',
          catNme: '棋牌游戏',
          type: 4,
          catCode: 'card',
          url: '/board'
        },
        {
          navId: '6',
          catNme: '捕鱼游戏',
          type: 5,
          catCode: 'fish',
          url: '/fishing'
        }
      ]
      let arr = []
      navCodes.forEach((item) => {
        const navItemInit = configNavMap.find((nav) => nav.catCode === item.catCode)
        const navItem = { ...navItemInit, ...item }
        arr.push(navItem)
      })
      this.topNav = [...defNavMap, ...arr]
    },
    // 第三方游戏 分组
    groupAllPlatListObj(list) {
      let card = [],
        sport = [],
        live = [],
        ele = [],
        fish = []
      if (list.length) {
        list.forEach((item) => {
          if (item.catCode === 'card') {
            card = item.games
          } else if (item.catCode === 'sport') {
            sport = item.games
          } else if (item.catCode === 'live') {
            live = item.games
          } else if (item.catCode === 'ele') {
            ele = item.games
          } else if (item.catCode === 'fish') {
            fish = item.games
          }
        })
      }
      return { card, sport, live, ele, fish }
    },
    // 彩票分组
    groupAllLottery(list) {
      let lhcList = [],
        scList = [],
        sscList = [],
        k3List = [],
        otherList = []
      if (list.length) {
        list.forEach((item) => {
          if (item.type === 4) {
            lhcList.push(item)
          } else if (item.type === 3) {
            scList.push(item)
          } else if (item.type === 2) {
            sscList.push(item)
          } else if (item.type === 1) {
            k3List.push(item)
          } else {
            otherList.push(item)
          }
        })
      }
      this.allLotteryObj = { lhcList, scList, sscList, k3List, otherList }
    }

    // async getAllLotteryFn() {
    //   const { result } = await getAllLottery()
    //   if (result) {
    //     this.allLotteryList = result.list
    //     // this.groupAllLottery(result.list)
    //   }
    // }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useHomeStore, import.meta.hot))
}
