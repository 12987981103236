import request from '@/utils/request'

export function homeDomain(params) {
  return request({
    url: `/home/domain`,
    method: 'get',
    params
  })
}

//获取游戏分类
export function getAllGame() {
  return request({
    url: '/home/getAllGame',
    method: 'get'
  })
}

//获取所有彩种
export function getAllLottery() {
  return request({
    url: '/lottery/getAllLottery',
    method: 'get'
  })
}

//获取所有平台游戏列表
export function getAllPlat() {
  return request({
    url: '/plat/getAllPlat',
    method: 'get'
  })
}

//获取指定平台游戏列表
export function getEleGame(gameCode, params) {
  return request({
    url: `/plat/getElegame/${gameCode}`,
    method: 'get',
    params
  })
}

//进入第三方游戏
export function platLogin(params) {
  return request({
    url: `/plat/login`,
    method: 'get',
    params
  })
}
export function bannerList(params) {
  return request({
    url: `/home/banner/web/list`,
    method: 'get',
    params
  })
}
export function winUserList(params) {
  return request({
    url: `/home/winUser/list`,
    method: 'get',
    params
  })
}
export function lotteryResultList(params) {
  return request({
    url: `/home/lotteryResult/list`,
    method: 'get',
    params
  })
}
export function activityList(params) {
  return request({
    url: `/home/activity/list`,
    method: 'get',
    params
  })
}
export function noticeList(params) {
  return request({
    url: `/home/notice/list`,
    method: 'get',
    params
  })
}
export function signDetail(params) {
  return request({
    url: `/activity/sign/detail`,
    method: 'get',
    params
  })
}
export function signStatus(params) {
  return request({
    url: `/activity/sign/status`,
    method: 'get',
    params
  })
}
export function signPost(data) {
  return request({
    url: `/activity/sign/post`,
    method: 'post',
    data
  })
}
export function signRecord(params) {
  return request({
    url: `/activity/sign/record`,
    method: 'get',
    params
  })
}
export function rouletteStatus(params) {
  return request({
    url: `/activity/roulette/status`,
    method: 'get',
    params
  })
}
export function roulettePost(data) {
  return request({
    url: `/activity/roulette/post`,
    method: 'post',
    data
  })
}
export function rouletteRecords(params) {
  return request({
    url: `/activity/roulette/record`,
    method: 'get',
    params
  })
}
export function rouletteDetils(params) {
  return request({
    url: `/activity/roulette/detail`,
    method: 'get',
    params
  })
}
export function hbyPost(data) {
  return request({
    url: `/activity/hby/post`,
    method: 'post',
    data
  })
}
export function hbyRecord(params) {
  return request({
    url: `/activity/hby/record`,
    method: 'get',
    params
  })
}

/**
 * 获取网站内容 aboutus
 * @param {*} key 关键字(后台配置)
 * @returns
 */
export function getSiteContent(key) {
  return request({
    url: `home/textInfo/${key}`,
    method: 'get'
  })
}
