<template>
  <div class="box">
    <div class="notice-box" @click="openNoticeDialog">
      <i class="icon gs-sound notice-icon"></i>
      <div class="marquee">
        <span class="marquee-txt">{{ homeStore.domainInform }}</span>
      </div>
    </div>
    <ul class="nav">
      <!-- <li class="item" v-if="homeStore.lineCheckLink">
        <a :href="homeStore.lineCheckLink" target="_blank">线路检测</a>
      </li> -->
      <li class="item" v-if="userStore.isProxy">
        <router-link to="/proxy" target="_blank">代理中心</router-link>
      </li>
      <li class="item" v-if="userStore.hasLogin">
        <router-link to="/memberCentre/vip">VIP权益</router-link>
      </li>
      <UserWallet />
      <div class="img-box" v-if="userStore.hasLogin">
        <el-button class="btn" @click="goPage('/memberCentre/deposit')">
          <!-- <img class="img" src="@/assets/home/ico_deposit.png" alt="" /> -->
          <span>充值</span>
        </el-button>
        <el-button class="btn" @click="goPage('/memberCentre/withdraw')">
          <!-- <img class="img" src="@/assets/home/ico_withdraw.png" alt="" /> -->
          <span>提现</span>
        </el-button>
        <el-button class="btn" @click="goPage('/memberCentre/balanceTransfer')">
          <span>额度转换</span>
        </el-button>
      </div>
      <UserName />
    </ul>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import UserName from './UserName.vue'
import UserWallet from './UserWallet.vue'
import { useHomeStore } from '@/stores/home.js'
import { useUserStore } from '@/stores/user.js'
const router = useRouter()
const homeStore = useHomeStore()
const userStore = useUserStore()
const showNoticeDialog = inject('showNoticeDialog')

const openNoticeDialog = () => {
  if (showNoticeDialog && showNoticeDialog.value) {
    showNoticeDialog.value()
  }
}

const goPage = (path) => {
  router.push(path)
}
</script>

<style lang="scss" scoped>
.primary {
  color: $primaryColor;
}

.box {
  height: 40px;
  @include flexBetween;
  // user-select: none;
  // background: #fafafa;

  .notice-box {
    // width: 580px;
    // flex: 1;
    width: 370px;
    height: 100%;
    @include flexCenter;
    padding: 0 10px;
    box-sizing: border-box;
    cursor: pointer;
    gap: 10px;
    color: #5f5f5f;
    &:hover .notice-icon {
      color: $primaryColor;
    }

    .notice-icon {
      font-size: 14px;
      color: #bfbfbf;
    }

    .marquee {
      flex: 1;
      box-sizing: border-box;
      word-break: break-all;
      white-space: nowrap;
      overflow: hidden;
      .marquee-txt {
        display: inline-block;
        padding-left: 100%;
        /* 从右至左开始滚动 */
        animation: marqueeTransform 30s linear infinite;
        font-family: PingFangSC;
        font-size: 14px;
        text-align: center;
        &:hover {
          color: #333;
        }
      }

      @keyframes marqueeTransform {
        0% {
          transform: translate(0, 0);
        }

        100% {
          transform: translate(-100%, 0);
        }
      }
    }
  }

  .nav {
    height: 100%;
    display: flex;

    .item {
      height: 100%;
      padding: 0 10px;
      flex-grow: 0;
      font-size: 14px;
      color: #333;
      cursor: pointer;

      @include flexCenter;

      &:hover {
        color: $primaryColor;
      }

      a {
        color: #333;

        &:hover {
          color: $primaryColor;
        }
      }
    }
  }

  .img-box {
    height: 100%;
    @include flexCenter;
    margin-left: 5px;
    gap: 8px;

    .img {
      height: 100%;
      @include flexCenter;
      cursor: pointer;

      img {
        width: 18px;
        height: 18px;
      }
    }
    .btn {
      padding: 2px 8px;
      border-radius: 4px;
      line-height: 14px;
      height: 26px;

      // padding: 5px 12px;
      // border-radius: 500px;
      border: 1px solid #c8ddfa;
      background-color: #f7faff;
      font-size: 14px;
      color: #5f5f5f;
      @include flexCenter;
      &:hover {
        background-color: #fff;
        // color: $primaryColor;
        // border-color: $primaryColor;
      }
      .img {
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }
    }
    .el-button + .el-button {
      margin-left: 0;
    }
  }

  .price-box,
  .name-box {
    @include flexCenter;
    margin-left: 20px;
    cursor: pointer;

    .label {
      font-size: 14px;
      color: #333;
      margin-right: 5px;
    }

    .val {
      font-size: 14px;
      font-weight: 600;
    }

    .num {
      font-size: 12px;
      font-weight: 500;
      padding: 2px 4px;
      background-color: $primaryColor;
      color: #fff;
      border-radius: 500px;
      margin-left: 6px;
    }
  }
}
</style>
