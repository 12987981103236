<template>
  <div class="page">
    <div class="title">支持的支付方式</div>
    <ul class="logos">
      <li class="logo" v-for="item in payType" :key="item">
        <el-image class="logo-img" :src="item" />
      </li>
    </ul>
    <div class="title">支持的游戏产品</div>
    <ul class="logos product">
      <li class="logo_li" v-for="(item, index) in product" :key="index">
        <i :class="'icon gs-logo_' + item"></i>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import usdt from '@/assets/pay-logo/usdt.png'
import wx from '@/assets/pay-logo/wx.png'
import zfb from '@/assets/pay-logo/zfb.png'
import qq from '@/assets/pay-logo/qq.png'
import yl from '@/assets/pay-logo/yl.png'
import ysf from '@/assets/pay-logo/ysf.png'
import jd from '@/assets/pay-logo/jd.png'
import icbc from '@/assets/pay-logo/icbc.png'

const payType = ref([usdt, wx, zfb, qq, icbc, ysf, yl, jd])
const product = ref([
  'p3d_ob',
  'p3d_og',
  'p3d_pg',
  'p3d_ag',
  'p3d_bbin',
  'p3d_bg',
  'p3d_cq9',
  'p3d_crown',
  'p3d_dg',
  'p3d_dt',
  'p3d_f',
  'p3d_jdb',
  'p3d_ky',
  'p3d_leg',
  'p3d_panda',
  'p3d_sb',
  'p3d_w'
])
</script>

<style lang="scss" scoped>
.page {
  padding-top: 20px;

  .title {
    text-align: center;
    font-size: 16px;
    color: #999999;
  }

  .logos {
    @include flexCenter;
    padding: 23px 0;

    .logo {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      overflow: hidden;
      // cursor: pointer;
      // background-color: #eee;
      margin: 0 15px;

      .logo-img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .product {
    gap: 10px;

    .logo_li {
      .logo {
        background: none;
      }

      .icon {
        font-size: 22px;
        color: #79849e;
      }
    }
  }
}
</style>
